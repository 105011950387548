import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='p-5'>
      <h1>Politique de Confidentialité</h1>
      <p><strong>Date de dernière mise à jour :</strong> 18/10/2024</p>
      
      <h2 >1. Informations que nous collectons</h2>
      <p>
        Nous collectons les types d'informations suivants :
        <ul>
          <li><strong>Informations personnelles</strong> : lorsque vous nous contactez, nous pouvons collecter des informations telles que votre nom, adresse e-mail et numéro de téléphone.</li>
          <li><strong>Données de navigation</strong> : nous collectons des données sur votre utilisation de notre site, y compris votre adresse IP, le type de navigateur, la langue du navigateur, la date et l'heure de votre visite, ainsi que les pages que vous avez consultées.</li>
        </ul>
      </p>
      
      <h2>2. Utilisation de vos informations</h2>
      <p>
        Nous utilisons vos informations pour :
        <ul>
          <li>Fournir et améliorer nos services.</li>
          <li>Communiquer avec vous, y compris pour vous envoyer des informations sur nos services.</li>
          <li>Analyser l'utilisation de notre site pour améliorer notre offre.</li>
        </ul>
      </p>

      <h2>3. Partage de vos informations</h2>
      <p>
        Nous ne vendons pas vos informations personnelles à des tiers. Nous pouvons partager vos informations avec :
        <ul>
          <li>Des prestataires de services qui nous aident à gérer notre site Web et nos services.</li>
          <li>Les autorités légales si la loi l'exige ou pour protéger nos droits.</li>
        </ul>
      </p>
      
      <h2>4. Sécurité de vos informations</h2>
      <p>
        Nous prenons des mesures raisonnables pour protéger vos informations personnelles. Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est entièrement sécurisée.
      </p>

      <h2>5. Vos droits</h2>
      <p>
        Vous avez le droit d'accéder à vos informations personnelles, de les corriger ou de demander leur suppression. Pour exercer ces droits, veuillez nous contacter à l'adresse suivante : [Insérer l'adresse e-mail de contact].
      </p>
      
      <h2>6. Modifications de cette politique</h2>
      <p>
        Nous pouvons mettre à jour cette Politique de Confidentialité de temps à autre. Les changements entreront en vigueur dès leur publication sur notre site. Nous vous encourageons à consulter régulièrement cette page.
      </p>

      <h2>7. Contact</h2>
      <p>
        Pour toute question concernant cette Politique de Confidentialité, veuillez nous contacter à l'adresse : contact@catalunyaconnect.fr.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
