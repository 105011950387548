import React, { useEffect, useState } from "react";
import { ScrollRestoration } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import About from "../Components/About/About";

import Services from "../Components/Services/Services";
import WorkeProcess from "../Components/WorkeProcess/WorkeProcess";
import Testimonial from "../Components/Testimonial/Testimonial";
import Blogs from "../Components/Blogs/Blogs";
import MarqueeWapper from "../Components/Shared/Marquee/MarqueeWapper";
import Preloader from "../Components/Shared/Preloader/Preloader";


const Home = () => {
  const [isFetching, setIsFetching] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setIsFetching(false);
    }, 300);
  }, []);
  const jsonLdData = {
    "@context": "https://schema.org",
    "@type": ["LocalBusiness", "Organization", "WebSite", "CreativeWork"],
    "name": "CatalunyaConnect",
    "url": "https://catalunyaconnect.fr",
    "logo": "https://catalunyaconnect.fr/logo.png",
    "image": [
      "https://catalunyaconnect.fr/logo.png"
    ],
    "description": "CatalunyaConnect est une agence spécialisée en création de sites web, SEO, et marketing digital à Perpignan. Nous offrons des services personnalisés pour améliorer la visibilité et la performance de votre entreprise en ligne, incluant la création de sites web professionnels, le référencement naturel, et des stratégies de marketing digital sur mesure.",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "résidence la promenade",
      "addressLocality": "Perpignan",
      "addressRegion": "Occitanie",
      "postalCode": "66000",
      "addressCountry": "FR"
    },
    "telephone": "+33 7 84 29 88 01",
    "sameAs": [
      "https://www.facebook.com/people/CatalunyaConnect/61557100655573/",
      "https://x.com/Catalunyacnnect",
      "https://g.co/kgs/Qwhw4U3"
    ],
    "areaServed": ["Pyrénées-Orientales", "Perpignan", "Occitanie", "France"],
    "hasOfferCatalog": {
      "@type": "OfferCatalog",
      "name": "Services de création de sites web",
      "itemListElement": [
        {
          "@type": "Offer",
          "name": "Création de site web professionnel",
          "url": "https://catalunyaconnect.fr/services/creation-de-site-web"
        },
        {
          "@type": "Offer",
          "name": "Optimisation SEO",
          "url": "https://catalunyaconnect.fr/services/seo"
        },
        {
          "@type": "Offer",
          "name": "Campagnes Google Ads et publicité digitale",
          "url": "https://catalunyaconnect.fr/services/marketing-digital"
        }
      ]
    },
    "email": "contact@catalunyaconnect.fr",
    "paymentAccepted": "Espèces, Carte bancaire, Virement bancaire",
    "availableLanguage": ["Français", "Anglais", "Arabe"],
    "potentialAction": {
      "@type": "Action",
      "name": "Demander un devis de création de site internet",
      "target": "https://catalunyaconnect.fr/contact"
    },
    "serviceType": "Création de sites internet, SEO, Marketing digital",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "5",
      "reviewCount": "15"
    },
    "keywords": "création site internet Perpignan, SEO Perpignan, marketing digital Perpignan, développement web, référencement naturel, stratégie digitale Perpignan, création de sites web professionnels, Agence web Perpignan, agence de communication Perpignan, création site e-commerce, refonte site internet, référencement SEO Perpignan, Google Ads Perpignan, optimisation SEO",
    
    // Ajout du type WebSite
    "mainEntityOfPage": {
      "@type": "WebSite",
      "url": "https://catalunyaconnect.fr"
    },
    
    // Ajout du type CreativeWork (pour le contenu comme les blogs ou les études de cas)
    "mainEntityOfPage": {
      "@type": "CreativeWork",
      "name": "Services de création de sites web et SEO à Perpignan",
      "url": "https://catalunyaconnect.fr"
    }
  };
  

  return (
    <>
        <Helmet>
        <title>CatalunyaConnect- AGENCE WEB a Perpignan</title>
        <meta name="description" content="CatalunyaConnect est une agence web basée à Perpignan, spécialisée dans la création de sites web , Marketing digital et SEO" />
        <meta name="keywords" content="web development, web design, Perpignan, website creation, CatalunyaConnect" />
        <link rel="canonical" href="https://catalunyaconnect.fr" /> {/* Canonical URL for homepage */}
        <script type="application/ld+json">
          {JSON.stringify(jsonLdData)}
        </script>
        <meta property="og:url" content="https://catalunyaconnect.fr"/>

        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
<main>
      {isFetching && <Preloader />}
      <MarqueeWapper direction="left"  />
      <About />
      <MarqueeWapper direction="right"  />
      
      <Services isHeading={false} />
      <h2 className="w-100 d-flex text-center p-5 h2">Votre site web en quelques etapes</h2>
      <WorkeProcess />
      <Testimonial />
      <Blogs />
      <ScrollRestoration/>
      </main>
    </>
  );
};

export default Home;
