import Pricing from "../../Components/Pricing/Pricing";
import React from 'react'
import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";

const jsonLdData = 
{
  "@context": "https://schema.org",
  "@type": "WebPage",
  "@id": "https://catalunyaconnect.fr/tarifs",
  "url": "https://catalunyaconnect.fr/tarifs",
  "name": "Tarifs - CatalunyaConnect",
  "description": "Découvrez nos tarifs pour la création de sites web, le marketing digital et le SEO. Des forfaits adaptés à vos besoins à partir de 499€.",
  "inLanguage": "fr",
  "isPartOf": {
    "@type": "WebSite",
    "url": "https://catalunyaconnect.fr"
  },
  "mainEntity": {
    "@type": "OfferCatalog",
    "name": "Tarifs de CatalunyaConnect",
    "itemListElement": [
      {
        "@type": "Offer",
        "name": "Création de site web - Forfait Basic",
        "description": "Site web de 3 pages, adapté aux petites entreprises ou artisans.",
        "price": "499",
        "priceCurrency": "EUR",
        "url": "https://catalunyaconnect.fr/tarifs#basic"
      },
      {
        "@type": "Offer",
        "name": "Création de site web - Forfait Standard",
        "description": "Site web de 5 pages avec formulaire de contact inclus.",
        "price": "799",
        "priceCurrency": "EUR",
        "url": "https://catalunyaconnect.fr/tarifs#standard"
      },
      {
        "@type": "Offer",
        "name": "Création de site web - Forfait Premium",
        "description": "Site web complet avec 10 pages, SEO de base et maintenance.",
        "price": "1799",
        "priceCurrency": "EUR",
        "url": "https://catalunyaconnect.fr/tarifs#premium"
      }
    ]
  },
  "publisher": {
    "@type": "Organization",
    "name": "CatalunyaConnect",
    "url": "https://catalunyaconnect.fr",
    "logo": {
      "@type": "ImageObject",
      "url": "https://catalunyaconnect.fr/logo.png",
      "width": 600,
      "height": 60
    },
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Perpignan",
      "addressCountry": "FR"
    },
    "contactPoint": {
      "@type": "ContactPoint",
      "contactType": "customer service",
      "email": "contact@catalunyaconnect.fr",
      "telephone": "+33-7-84-29-88-01"
    }
  }
}


function Tarifs() {
  return (
    <>
    <Helmet>

    <title>CatalunyaConnect- AGENCE WEB a Perpignan</title>
    <meta name="description" content="CatalunyaConnect est une agence web basée à Perpignan, spécialisée dans la création de sites web , Marketing digital et SEO" />
    <meta name="keywords" content="web development, web design, Perpignan, website creation, CatalunyaConnect" />
    <link rel="canonical" href="https://catalunyaconnect.fr/tarifs" /> {/* Canonical URL for homepage */}
    <meta name="msvalidate.01" content="EA6CA7422B2AB6DBA21D556A601377BA" />
    <meta property="og:url" content="https://catalunyaconnect.fr/tarifs" />
    <script type="application/ld+json">
          {JSON.stringify(jsonLdData)}
        </script>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    
  </Helmet>
    <h1 className="col-12 text-center p-5 mt-5 fst-italic">Nos tarifs de création de site web</h1>
    <Pricing/>
    <ScrollRestoration />
    </>
  )
}

export default Tarifs