import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Instagram } from "react-bootstrap-icons";
import workingImage from '../../assets/img/banner/working-on-desktop.svg';

import scrollDown from "../../assets/img/banner/scroll-down.png";
import dial from "../../assets/img/banner/dial.png";

const socalIcon = [
  {
    id: 1,
    icon: <Facebook />,
    lien : "https://www.facebook.com/profile.php?id=61557100655573"
  },
  {
    id: 2,
    icon: <Twitter />,
    lien : "https://x.com/Catalunyacnnect"
  },
  {
    id: 3,
    icon: <Instagram />,
    lien : "https://www.instagram.com/baitechebillal/"
  },
];

const Banner = () => {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [position, setPosition] = useState(false);

  useEffect(() => {
    if(!position){
      setPosition(true)
    }
  }, []);

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  return (
    <section id="home">
      <div className="d-flex justify-content-center">
        <div className="d-flex col-10">
          <div className="col-12 col-lg-6 ">
            <div className="banner__content">
              <span className="d-block mx-5 bn__currently h3">
                Transformez vos idées en réalité
              </span>

              <h1 className="px-5 h1">
                <span className="hone designers" data-text="CatalunyaConnect">CatalunyaConnect</span>
                <br />
                <span className="d-block h5">
                  <br />-  CRÉATION DE SITES INTERNET <br /><br />
                  -  SEO<br /> <br />-  Marketing digital
                </span>
              </h1>
            </div>
          </div>

          <div className=" d-none d-lg-block col-lg-6">
            
          <img 
  src={workingImage}
  
  alt="Hero Image"
  className="hero-img"
/>
          </div>
        </div>
      </div>

      <div className="banner__leftinfo  ">
        <div className="left__infomobile">
          <Link to={"tel:+33784298801"}>
            <img src={dial} alt="Phone Dial" loading="lazy" />
          </Link>
          <Link to={"tel:+33784298801"}>(+33)78 42 98 801</Link>
        </div>

        <div className="right__infoscroll">
          <Link to={"#services"} className="scroll">
            Services
          </Link>
          <Link className="scroll__bar">
            <img src={scrollDown} alt="Scroll Down" loading="lazy" />
          </Link>
        </div>
      </div>

      <div className="banner__rightinfo">
        <div className="right__infoscroll">
          <Link to={""} className="scroll">
            Follow Me
          </Link>
          <Link to={""} className="scroll__bar">
            <img src={scrollDown} alt="Scroll Down" loading="lazy" />
          </Link>
        </div>

        <div className="banner__xlsocial">
          <ul className="banner__soci d-grid justify-content-center">
            {socalIcon.map(({ icon, id , lien}) => (
              <li key={id}>
                <Link to={lien} target="_blank" rel="noopener noreferrer">
                  <i>{icon}</i>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Banner;
