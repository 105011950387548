import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import "./blogDetails.scss";
import { Helmet } from "react-helmet-async";
import { ScrollRestoration } from "react-router-dom";
import AdSense from '../../Components/Ads/ads';


const BlogDetails = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // GraphQL query to fetch a single blog post by slug
  const query = `
    query {
      post(id: "${slug}", idType: SLUG) {
        title
        content
        excerpt
        date
        featuredImage {
          node {
            sourceUrl
          }
        }
      }
    }
  `;

  useEffect(() => {
    // Fetch blog post using Axios
    axios({
      url: 'https://latest.catalunyaconnect.fr/graphql', // Your GraphQL endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        query,
      },
    })
      .then((response) => {
        setBlog(response.data.data.post); // Access the post data
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blog: ', error);
        setError('An error occurred while fetching the blog post');
        setLoading(false);
      });
  }, [slug]);

  const extractMetaDescription = (content) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = content; // Convert HTML to text
    return tempDiv.innerText.slice(0, 150) + '...'; // Get first 150 characters and add ellipsis
  };

  const metaDescription = blog ? extractMetaDescription(blog.excerpt) : '';

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Self-referencing canonical URL
  const canonicalUrl = blog ? `https://catalunyaconnect.fr/blog/${slug}` : '';
  return (
    <>
      <Helmet>
        <title>{blog.title}</title>
        <meta name="description" content={metaDescription} />
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />} {/* Set the canonical URL */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content={canonicalUrl}/>

      </Helmet>
      <div className="blog-details mx-auto col-md-8  px-5 text-white">
        <h1 className='h2'>{blog.title}</h1>
        <div
          className="blog-content text-white content"
          dangerouslySetInnerHTML={{ __html: blog.content }} // Render HTML content
        />
        <AdSense/>
      </div>
      

      <ScrollRestoration />

    </>
  );
};

export default BlogDetails;
