import Title from "../Shared/Title/Title";
import Service from "./Service";

const serviceList = [
  {
    id: "01",
    heading: "Création de sites web",
    subHeading: "CMS ou from scratch",
    para: "Chez CatalunyaConnect, nous savons qu’un site web bien conçu et fonctionnel est essentiel pour toute entreprise. Que vous soyez une petite entreprise, un artisan ou un professionnel, un site web de qualité est l’un des meilleurs moyens d’attirer de nouveaux clients.",
    lelien: "/services/creation-de-site-web"
  },
  {
    id: "02",
    heading: "Marketing Digital",
    subHeading: "Google ads",
    para: "Chez CatalunyaConnect, nous mettons notre expertise en marketing digital à votre service pour accroître votre visibilité et atteindre vos objectifs commerciaux. Dans un marché de plus en plus compétitif, le marketing digital est essentiel pour capter l’attention de vos prospects, fidéliser vos clients et vous démarquer de la concurrence.",
    lelien: "/services/marketing-digital"
  },
  {
    id: "03",
    heading: "SEO",
    subHeading: "optimisation pour moteur de recherche",
    para: "Le SEO est une stratégie clé pour améliorer la visibilité de votre site sur les moteurs de recherche. Chez CatalunyaConnect, nous optimisons votre site pour qu'il soit mieux classé sur Google et d'autres moteurs de recherche, attirant ainsi un trafic organique qualifié.",
    lelien: "/services/seo"
  },
];

const Services = ({ isHeading }) => {
  return (
    <section
      id="services"
      className={`service__section overhid ${isHeading && "pt-120"}  pb-120`}
    >
      <div className=" px-5">
        {isHeading && (
          <Title
            mainTitle="Découvrez Nos Services plus en détails"
            sortTitle="Services"
          />
        )}

        <div className={`service__uniquewrap`}>
            {serviceList.map(({ id, heading, subHeading, para, lelien }) => (
              <Service
                key={id}
                id={id}
                heading={heading}
                subHeading={subHeading}
                para={para}
                servicelink={lelien}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
