import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className='p-5'>
      <h1>Conditions Générales</h1>
      <p><strong>Date de dernière mise à jour :</strong> 19/10/2024</p>
      
      <h2>1. Acceptation des conditions</h2>
      <p>
        En utilisant notre site Web, vous acceptez ces Conditions Générales. Si vous n'êtes pas d'accord, veuillez ne pas utiliser notre site.
      </p>
      
      <h2>2. Services</h2>
      <p>
        CatalunyaConnect propose des services de création de sites web, marketing digital, et SEO. Les détails de ces services sont disponibles sur notre site.
      </p>

      <h2>3. Tarifs et paiements</h2>
      <p>
        Les tarifs de nos services sont affichés sur notre site. Le paiement doit être effectué selon les modalités spécifiées lors de la commande.
      </p>

      <h2>4. Propriété intellectuelle</h2>
      <p>
        Tous les contenus de notre site, y compris les textes, images et logos, sont protégés par les lois sur la propriété intellectuelle.
      </p>

      <h2>5. Limitation de responsabilité</h2>
      <p>
        CatalunyaConnect ne pourra être tenu responsable des dommages directs ou indirects résultant de l'utilisation de notre site ou de nos services.
      </p>

      <h2>6. Modifications des conditions</h2>
      <p>
        Nous nous réservons le droit de modifier ces Conditions Générales à tout moment. Les modifications entreront en vigueur dès leur publication sur notre site.
      </p>

      <h2>7. Contact</h2>
      <p>
        Pour toute question concernant ces Conditions Générales, veuillez nous contacter à l'adresse : contact@catalunyaconnect.fr.
      </p>
    </div>
  );
};

export default TermsAndConditions;
