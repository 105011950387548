import React from "react";
import basic from "../../assets/img/project/basic.png";
import warranty from "../../assets/img/project/warranty.png";
import premium from "../../assets/img/project/premium-quality.png";
import Price from "./Price";
import Title from "../Shared/Title/Title";
const planData = [
  {
    id: 1,
    planName: "Basic",
    price: "€499",
    
    fetcher: [
      "Design personnalisé",
      "Site vitrine jusqu’à 5 pages",
      "Version mobile optimisée",
      "Formulaire de contact",
      "Intégration des réseaux sociaux",
    ],
    image: basic,
  },
  {
    id: 2,
    planName: "Standard",
    price: "€999",
    
    fetcher: [
      "Tout ce qui est inclus dans le Pack Basic",
      "Site jusqu’à 10 pages",
      "Blog intégré",
      "SEO de base",
      "Google Analytics",
    ],
    image: warranty,
  },
  {
    id: 3,
    planName: "Premium",
    price: "€1999",
    
    fetcher: [
      "Tout ce qui est inclus dans le Pack Standard",
      "Site e-commerce jusqu’à 50 produits",
      "Système de paiement intégré",
      "Optimisation SEO avancée",
      "Formation à l’utilisation du CMS",
    ],
    image: premium,
  },
];
const Pricing = () => {
  return (
    <section className="container">
      
       
        <div className="row col-12 g-4 justify-content-center">
          {planData.map(({ id, image, planName, fetcher, price, time }) => (
            <Price
              key={id}
              image={image}
              planName={planName}
              price={price}
              time={time}
              fetcher={fetcher}
              id={id}
            />
          ))}
        </div>
    
    </section>
  );
};

export default Pricing;
