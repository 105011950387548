// Blog Component
import React from "react";
import { useState } from "react";
import { Eye } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import Lightbox from "../Shared/LightBox/LightBox";

const Blog = ({ heading, image, index, slug }) => {
  const [currentId, setCurrentId] = useState(0);
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentId(index);
    setLightboxOpen(true);
  };

  return (
    <>
      <div
        className="service__unique__item pb-40 pt-40"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="left__service">
          <div className="serial__adjust">
            <div className="cont">
              <h3>
              <Link to={`/blog/${slug}`}>{heading}</Link> {/* Using heading prop */}
              </h3>
            </div>
          </div>
          <div className="opa__thumb imgc">
            <img
             loading="lazy"
              src={image} // Using image prop
              alt="img-opa"
              onClick={() => openLightbox(index)}
            />
          </div>
        </div>
        <div className="common__icon imgc">
        <Link to={`/blog/${slug}`}><Eye className="i" /></Link>
          
        </div>
      </div>
      {lightboxOpen && (
        <Lightbox
          images={blogImages}
          setLightboxOpen={setLightboxOpen}
          currentId={currentId}
        />
      )}
    </>
  );
};

export default Blog;
