import React, { useEffect, useState } from "react";
import sectionStar from "../../assets/img/about/section-star.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Experience from "./Experience";
import Education from "./Education";
import Skills from "./Creation-site-web";


const tabList = [
  {
    id: 1,
    name: "Création de site web",
    value: "creation",
  },
  {
    id: 2,
    name: "Marketing Digital",
    value: "experience",
  },
  {
    id: 3,
    name: "SEO",
    value: "education",
  },
 
];

const About = () => {
  const [isTabActive, setIsTabAative] = useState("creation");
  
  useEffect(() => {
    AOS.init();
  }, []);

  const handleTabClick = (e) => {
    setIsTabAative(e);
  };

  return (
    <>
      <section className="about__section pt-120 pb-120 col-12"  id="about">
        <div >
          <div className="personal__head text-center">
            <img
            loading="lazy"
              src={sectionStar}
              className="mb-30"
              alt="star"
              data-aos="fade-up"
              data-aos-duration="1000"
            />
            <p className="descrp mx-2" data-aos="fade-up" data-aos-duration="1500">
            CatalunyaConnect est une agence web basée à Perpignan, spécialisée dans la création de sites web, l’optimisation SEO et le marketing digital. Nous nous engageons à fournir des solutions sur mesure pour aider les entreprises locales à se développer en ligne.
            </p>
          </div>
          <div className="singletab">
            <ul className="tablinks mx-2">
              {tabList.map(({ id, name, value }) => (
                <li
                  key={id}
                  className={`nav-links ${isTabActive === value ? "active" : ""} `}
                >
                  <button onClick={() => handleTabClick(value)} className="tablink">
                    {name}
                  </button>
                </li>
              ))}
            </ul>
            <div className="tabcontents">
              
              <Experience isTabActive={isTabActive} />
              <Education isTabActive={isTabActive} />
              <Skills isTabActive={isTabActive} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
