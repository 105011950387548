import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import Preloader from "../../Components/Shared/Preloader/Preloader";
import axios from 'axios';
import { Helmet } from "react-helmet-async";
import AdSense from '../../Components/Ads/ads';

const AllBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [endCursor, setEndCursor] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);

  const location = useLocation(); // Get the current location (route)

  const fetchBlogs = (cursor = null) => {
    setLoadingMore(true);

    const query = `
      query GetBlogs($first: Int, $after: String) {
        posts(first: $first, after: $after) {
          pageInfo {
            hasNextPage
            endCursor
          }
          nodes {
            title
            slug
            content
            date
            featuredImage {
              node {
                sourceUrl
              }
            }
          }
        }
      }
    `;

    axios({
      url: 'https://latest.catalunyaconnect.fr/graphql',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        query,
        variables: {
          first: 6, // Number of posts to load per request
          after: cursor, // Pass cursor for pagination
        },
      },
    })
      .then((response) => {
        const newBlogs = response.data.data.posts.nodes;
        setBlogs((prevBlogs) => [...prevBlogs, ...newBlogs]); // Append new blogs to the existing list
        setHasNextPage(response.data.data.posts.pageInfo.hasNextPage);
        setEndCursor(response.data.data.posts.pageInfo.endCursor);
        setLoading(false);
        setLoadingMore(false);
      })
      .catch((error) => {
        console.error('Error fetching blogs: ', error);
        setError('An error occurred while fetching blogs');
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    // Only fetch blogs if the user is on the /blog route
    if (location.pathname === "/blog") {
      fetchBlogs(); // Initial fetch
    } else {
      setLoading(false); // Avoid showing preloader on other pages
    }
  }, [location.pathname]);

  if (loading) return <Preloader />;
  if (error) return <p>{error}</p>;

  return (
    <>
      <Helmet>
        <title>CatalunyaConnect-blog</title>
        <meta name="description" content="CatalunyaConnect est une agence web basée à Perpignan, spécialisée dans la création de sites web , Marketing digital et SEO" />
        <meta name="keywords" content="web development, web design, Perpignan, website creation, CatalunyaConnect" />
        <link rel="canonical" href="https://catalunyaconnect.fr/blog" /> {/* Canonical URL for the blog page */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <h1 className='text-center p-5 mt-5 mb-5'>Nos articles</h1>
      <div className="row g-4 p-5">
        {blogs.map((blog) => (
          <div className="col-md-6 col-lg-4 d-flex" key={blog.slug}>
            <Link to={`/blog/${blog.slug}`} className="text-decoration-none">
              <div className="card bg-transparent text-white d-flex flex-column h-100">
                <div className="h-75">
                  <img 
                    src={blog.featuredImage.node.sourceUrl} 
                    alt={blog.title} 
                    className="card-img-top" 
                    style={{ height: '100%' }}
                    loading="lazy"
                    onError={(e) => e.target.src = 'path/to/fallback-image.jpg'}  // Fallback image
                  />
                </div>
                <div className="card-body d-flex">
                  <h4 className="card-title h5 d-flex flex-column">{blog.title}</h4>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {hasNextPage && (
        <div className="text-center">
          <button 
            className="rounded" 
            onClick={() => fetchBlogs(endCursor)} 
            disabled={loadingMore}
            style={{
              backgroundColor: '#c9f31d',
              borderColor: '#c9f31d',
              color: '#000',
              padding : '10px 20px'
            }}
          >
            {loadingMore ? 'Loading...' : 'Plus'}
          </button>
        </div>
      )}
      <AdSense/>
    </>
  );
};

export default AllBlogs;
