import React from "react";
import word from "../../assets/img/about/word.png?format=webp";
import website400 from "../../assets/img/responsive/site-maintenance-01-400w.webp";







import nextpic from "../../assets/img/about/nextpic.svg";

import shopify from "../../assets/img/about/shopify.svg";
import { Link } from "react-router-dom";

const skillsList = [
  {
    id: 1,
    skill: "Création De Site Vitrine ",
   
    image: word,
    pars:"Donnez une présence en ligne à votre entreprise avec un site vitrine moderne et efficace. Nous créons des sites responsives, esthétiques et faciles à naviguer, mettant en avant vos services et votre expertise. Avec un design personnalisé, un contenu optimisé, et une structure pensée pour attirer vos clients, nous vous aidons à marquer les esprits et à vous démarquer sur le web."
  },
  {
    id: 2,
    skill: "Refonte De Sites Existants",
   
    image: word,
    pars:"Redonnez une nouvelle vie à votre site web grâce à notre service de refonte. Nous améliorons l’ergonomie, le design, et les performances de votre site tout en conservant votre identité. Notre équipe effectue une analyse complète pour proposer des solutions adaptées à vos besoins et ainsi vous offrir une expérience utilisateur optimale."
  },
  {
    id: 3,
    skill: "Développement De Sites E-Commerce",
    
    image: shopify,
    pars:"Boostez votre activité en ligne avec un site e-commerce performant et sécurisé. Nous concevons des boutiques en ligne intuitives, faciles à gérer, et optimisées pour la conversion. Grâce à un design moderne et des fonctionnalités adaptées, nous vous aidons à développer vos ventes et à offrir une expérience d'achat fluide à vos clients."
  },
  {
    id: 4,
    skill: "Développement De Sites sur mesure",
    
    image: nextpic,
    pars:"Votre projet mérite une solution unique. Nous concevons des sites web sur mesure, adaptés à vos besoins spécifiques. De la création de fonctionnalités personnalisées à l’intégration d’outils spécifiques, nous travaillons avec vous pour donner vie à votre vision. Avec une approche sur mesure, nous nous assurons que votre site répond parfaitement à vos attentes."
  },

];
const Skills = ({ isTabActive }) => {
  return (
    <div className={`tabitem ${isTabActive === "creation" ? "active" : ""} `}>
      <div className="about__v1wrap">
        <div className="row g-4 d-flex align-items-lg-start align-items-center">
      

          <div className="col-lg-12">
            <div className="about__onecontent">
              <h2 className="h2">Nos services de création de site web</h2>
             
              <div className="about__contactwrap">
                <div className="row g-4">
                  {skillsList.map(({id, skill, image ,pars}) => {
                    return (
                      <div key={id} className="col-xxl-6 col-xl-6 col-lg-12 col-md-6">
                        <div className="abox myskill__item abox myskill__item h-100 flex flex-column  justify-between">
                          <div className="col-12 d-flex align-items-center  g-4 ">
                            <img src={image} alt="img" height={100} width={100} loading="lazy" className="mx-2"  />
                            <h3 className="h3 my-auto">{skill}</h3>
                         
                          </div>
                          <div className="mys">
                            
                            <p>{pars}</p>
                            
                            
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <Link className=" h5 italic text-center moreinfo" to={'/services/creation-de-site-web'}>En savoir plus</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
