import React from "react";
import { ScrollRestoration } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Services from "../Components/Services/Services";
import Metting from "../Components/Metting/Metting";
import WorkeProcess from "../Components/WorkeProcess/WorkeProcess";
import Pricing from "../Components/Pricing/Pricing";

const AllServices = () => {
  const serviceData = {
    "creation-de-site-web": {
      heading: "Création de site web",
      meta: "création de site web a perpignan , wordpress , site vitrine, refonte de site existant",
      description: "Chez CatalunyaConnect, nous savons qu’un site web bien conçu et fonctionnel est essentiel pour toute entreprise...",
      accordionList: [
        { id: "faq1", heading: "Quelle est la durée pour créer un site web ?", para: "Le temps de création varie en fonction de la complexité, entre 2 à 6 semaines." },
        { id: "faq2", heading: "Quelles sont les options de maintenance ?", para: "Nous offrons des services de maintenance pour garder votre site sécurisé et à jour." },
        { id: "faq3", heading: "Puis-je modifier le contenu de mon site moi-même après sa création ?", para: "Absolument ! Nous utilisons des CMS pour vous permettre de modifier facilement le contenu." },
        { id: "faq4", heading: "Proposez-vous un hébergement pour les sites web ?", para: "Oui, nous offrons des services d'hébergement adaptés à vos besoins." }
      ]
    },
    "seo": {
      meta: "services seo a perpignan on page seo off page seo et netlinking",
      heading: "SEO (Optimisation pour les moteurs de recherche)",
      description: "Le SEO est une stratégie clé pour améliorer la visibilité de votre site sur les moteurs de recherche...",
      accordionList: [
        { id: "faq1", heading: "Quels services SEO proposez-vous ?", para: "Nous offrons des services de SEO On-Page, Netlinking, et SEO technique." },
        { id: "faq2", heading: "Qu'est-ce que le SEO technique ?", para: "Le SEO technique améliore la vitesse de chargement, la compatibilité mobile, et d'autres aspects pour améliorer votre référencement." }
      ]
    }
  };

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": "Création de site web , SEO , et marketing digital a perpignan et ses alentours",
    "description": "Nous proposons des services complets de création de sites web et d'optimisation SEO à Perpignan et dans ses environs. Que vous ayez besoin d'un site vitrine moderne, d'une boutique en ligne e-commerce performante ou d'une refonte de site internet existant, nous avons les solutions adaptées à vos besoins. Spécialisés dans les plateformes WordPress et Shopify, nous vous accompagnons dans la création de sites web personnalisés, optimisés pour le référencement naturel. En plus de la création de sites, nous offrons des services d'hébergement web de qualité via Hostinger, garantissant une gestion optimale de vos sites avec des performances accrues. Nos prestations incluent également des audits SEO, la mise en place de stratégies de référencement local et national, ainsi que l'intégration de techniques avancées d'optimisation pour les moteurs de recherche (SEO) afin d'augmenter la visibilité de votre site sur Google et autres moteurs de recherche. Que vous soyez une petite entreprise, un artisan, ou un commerçant, nous vous aidons à améliorer votre présence en ligne et à atteindre vos objectifs numériques.",
    "provider": {
      "@type": "Organization",
      "name": "CatalunyaConnect",
      "url": "https://catalunyaconnect.fr",
      "logo": "https://catalunyaconnect.fr/logo.png",
      "sameAs": [
        "https://www.facebook.com/catalunyaconnect",
        "https://www.linkedin.com/company/catalunyaconnect"
      ]
    },
    "serviceType": [
      {
        "@type": "Service",
        "name": "Création de site web",
        "description": serviceData["creation-de-site-web"].description,
        "faq": serviceData["creation-de-site-web"].accordionList.map(faq => ({
          "@type": "Question",
          "name": faq.heading,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.para
          }
        }))
      },
      {
        "@type": "Service",
        "name": "SEO (Optimisation pour les moteurs de recherche)",
        "description": serviceData["seo"].description,
        "faq": serviceData["seo"].accordionList.map(faq => ({
          "@type": "Question",
          "name": faq.heading,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.para
          }
        }))
      }
    ]
  };

  return (
    <>
      <Helmet>
        <title>CatalunyaConnect-Création de site web , marketing digital et SEO</title>
        <meta name="description" content="Découvrez nos services de création de sites web , Marketing digital et SEO" />
        <meta name="keywords" content="developement webt, web design, Perpignan, création site web, CatalunyaConnect , Marketing , site wordpress" />
        <link rel="canonical" href="https://catalunyaconnect.fr/services" />
        <meta name="msvalidate.01" content="EA6CA7422B2AB6DBA21D556A601377BA" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:url" content="https://catalunyaconnect.fr/services" />

        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <Services isHeading={true} />
      <Metting />
      <h2 className="col-12 p-6 text-center mt-4 h2">Nos tarifs</h2>
      <Pricing />
      <h2 className="col-12 p-6 text-center mt-4 h2">Notre work process</h2>
      <WorkeProcess />
      <ScrollRestoration />
    </>
  );
};

export default AllServices;
