import { Facebook, Twitter, Linkedin, Youtube, Instagram } from "react-bootstrap-icons";

export const socialIcons = [
  {
    id: 1,
    icon: <Facebook />,
    lien:"https://www.facebook.com/profile.php?id=61557100655573",
  },
  {
    id: 2,
    icon: <Twitter />,
    lien:"https://x.com/Catalunyacnnect",
  },
 
  {
    id: 3,
    icon: <Instagram />,
    lien:"https://www.instagram.com/baitechebillal/",
  },
];
