import React from "react";
import Form from "../../Components/Shared/Form/Form";
import { ScrollRestoration } from "react-router-dom";
import Metting from "../../Components/Metting/Metting";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  return (
    <>
        <Helmet>
        <title>CatalunyaConnect- Contacts</title>
        <meta name="description" content="CatalunyaConnect est une agence web basée à Perpignan, spécialisée dans la création de sites web , Marketing digital et SEO" />
        <meta name="keywords" content="web development, web design, Perpignan, website creation, CatalunyaConnect" />
        <link rel="canonical" href="https://catalunyaconnect.fr/contact" /> {/* Canonical URL for homepage */}
        <meta name="msvalidate.01" content="EA6CA7422B2AB6DBA21D556A601377BA" />
        <meta property="og:url" content="https://catalunyaconnect.fr/contact" />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
     <h1 className="p-5 text-center mt-4">Parlons de votre projet web</h1>
     <Metting/>
      <section className="contact__section mt-5">
        <div className="container">
          <div className="row g-4">
            <div
              className="col-lg-8"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <div className="contact__box">
                <Form isColTwo={true} />
              </div>
            </div>
            <div
              className="col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1500"
            >
              <div className="contact__rightside cmn__bg">
                <h4 className="mb-30">ou alors contactez nous </h4>
                <div className="contact__item mb-20">
                  <span className="he1">Email</span>
                  <a href="mailto:contact@catalunyaconnect.fr">contact@catalunyaconnect.fr</a>
                </div>
                <div className="contact__item mb-20">
                  <span className="he1">Téléphone</span>
                  <a href="tel:+33784298801">+33784298801</a>
                </div>
                <div className="contact__item">
                  <span className="he1">Adress</span>
                  <span className="address">Résidence La Promenade Perpignan</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollRestoration />
    </>
  );
};

export default Contact;
