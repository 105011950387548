import React from "react";
import { Link } from "react-router-dom";

const Price = ({ image, planName, price, time, fetcher, id }) => {
  return (
    <div
      className="col-12 col-lg-4"
      data-aos="fade-up"
      data-aos-duration="1000"
    >
      <div
        className={`process__item price__item ${id === 2 && "pri__active"} `}
      >
        <div className="pri__iconbox">
          <img src={image} alt="img" />
        </div>
        <h2 className="white mb-24">{planName}</h2>
        <h3 className="big__title mb-24">
          {price}
          
        </h3>
        <ul className="pri__list">
          {fetcher?.map((fet, index) => (
            <li key={index}>{fet}</li>
          ))}
        </ul>
        <Link to={"tel:+33784298801"}
          className="cmn--btn d-flex align-items-center justify-content-center gap-2 w-100"
        >
          <span className="fw-bold">	&#9990;  Appeler</span>
          <span>
            <i className="bi bi-arrow-right fz-20"></i>
          </span>
        </Link>
      </div>
    </div>
  );
};

export default Price;
