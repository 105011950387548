import React from "react";
import {Envelope, GeoAlt ,Telephone} from "react-bootstrap-icons"
import teamwork from "/src/assets/img/about/teamwork.svg"
const Metting = () => {
  return (
    <section className="project__metting overhid pt-120 pb-120">
      <div className="container">
        <div className="row g-4 align-items-center">
          <div className="col-lg-7">
            <div className="pro__metting__content">
              <div className="project__head">
                <span
                  className="common__sub"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  Besoin d'un projet?
                </span>
                <h2
                  className="fw-500"
                  data-aos="fade-down"
                  data-aos-duration="1600"
                >
                  Travaillons ensemble
                </h2>
              </div>
              <div
                className="about__contbox__item pb-30"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <span className="icon">
                  <Envelope className="i"/>
                </span>
                <span className="box">
                  <span className="ptext fz-18 mb-1 d-block"> Email </span>
                  <a href="mailto:contact@catalunyaconnect.fr"> contact@catalunyaconnect.fr</a>
                </span>
              </div>
              <div
                className="about__contbox__item pb-30 pt-30"
                data-aos="fade-up"
                data-aos-duration="1500"
              >
                <span className="icon">
                  <Telephone className="i"/>
                </span>
                <span className="box">
                  <span className="ptext fz-18 mb-1 d-block"> Telephone </span>
                  <a href="tel:+33784298801"> +33784298801 </a>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div
              className="pro__mettingthumb"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img src={teamwork} alt="img" height={500}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Metting;
