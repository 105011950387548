import React from "react";
import Marquee from "react-fast-marquee";


const MarqueeWapper = ({images, direction}) => {
  return (
    <div className="marquee-wrapper text-slider p-auto">
      <div className="marquee-inner h-100 ">
        <Marquee className="h-100" direction={direction}>
          {
<p className="h2 text-dark align-middle h-100">| Création de site web | Marketing Digital | SEO |</p>         }
        </Marquee>
      </div>
    </div>
  );
};

export default MarqueeWapper;
