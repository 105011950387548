import React from "react";
import { ArrowUpRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
const Service = ({ heading, subHeading, para, id ,servicelink }) => {
  return (
    <div
      className="service__unique__item pb-40 pt-40 "
      data-aos="fade-up"
      data-aos-duration="1000"

    >
      <div className="left__service">
        <div className="serial__adjust">
          <span> {id} </span>
          <div className="cont">
            <span className="h5">{subHeading}</span>
            <h2>
              <Link to={servicelink}> {heading} </Link>
            </h2>
          </div>
        </div>
        <p className="pra">{para}</p>
      </div>
      <Link to={servicelink} className="common__icon">
        <ArrowUpRight className="i" height={60} width={60}/>
      </Link>
    </div>
  );
};

export default Service;
