import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Preloader from '../../Components/Shared/Preloader/Preloader';
import { Link } from 'react-router-dom';
import Blog from './Blog';
const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // GraphQL query to fetch blogs
  const query = `
    query {
      posts {
        nodes {
          title
          slug
          content
          date
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  `;

  useEffect(() => {
    // Fetch blogs using Axios
    axios({
      url: 'https://latest.catalunyaconnect.fr/graphql', // Your GraphQL endpoint
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        query,
      },
    })
      .then((response) => {
        setBlogs(response.data.data.posts.nodes); // Access the posts data
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching blogs: ', error);
        setError('An error occurred while fetching blogs');
        setLoading(false);
      });
  }, []);

  if (loading) return <Preloader/>;
  if (error) return <p>{error}</p>;

  return (
    <section className="blog__section overhid pt-120 pb-120" id="blog">
      <div className="col-12 mx-3">
        <div className="row g-4">
          <div className="col-xl-4 col-lg-3">
            <div className="project__head">
              <span
                className="common__sub"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                Nos articles
              </span>
              <h2
                className="fw-500 mt-3"
                data-aos="fade-down"
                data-aos-duration="1500"
              >
                articles récents
              </h2>
              <div className="blog__hoverbox">
                <Link
                to={"/blog"}
                  className="hover__circle wow fadeInUp"
                  data-wow-duration="1.6s"
                >
                  <span className="box">
                    <i className="bi bi-arrow-up-right"></i>
                    <span className="textmore"> lire nos articles </span>
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-lg-9">
            <div className="blog__rightwrap">
              <div className="service__uniquewrap">
                {blogs.slice(0, 3).map((blog, index) => (
                  <Blog   key={index}  
                  image={blog.featuredImage.node.sourceUrl} 
                  heading={blog.title} 
                  index={index} 
                  slug={blog.slug}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
