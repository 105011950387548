import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { List, ArrowRight } from "react-bootstrap-icons";

import Drawer from "../Drawer/Drawer";
import logo from "../../../assets/img/logo/logo.png";

const menuList = [
  {
    id: 1,
    path: "/",
    name: "Accueil",
  },
 
 
  {
    id: 2,
    path: "/services",
    name: "Services",
    dropDown: [
      {
        id: 1,
        name: "Création de site web",
        path: "/services/creation-de-site-web",
      },
      {
        id: 2,
        name: "Marketing digital",
        path: "/services/marketing-digital",
      },
      {
        id: 3,
        name: "SEO",
        path: "/services/seo",
      },
    ],
    
  },
  {
    id: 3,
    path: "/tarifs",
    name: "Tarifs",
    
  },
  {
    id: 4,
    path: "/blog",
    name: "Blog",
    
  },
 
 
  {
    id: 5,
    path: "/contact",
    name: "Contact",
  },
];

const Header = () => {
  const [isSidebarActive, setIsSidebarActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [dropDownId, setDropDownId] = useState(null);

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;

    scrollTop >= 35
      ? header.classList.add("menu-fixed", "animated", "fadeInDown")
      : header.classList.remove("menu-fixed");
  };

  const handleHeaderToggle = () => {
    setMenuActive(!menuActive);
  };

  const handleSubMenu = (id) => {
    setDropDownId(id);
  };

  // Function to close the menu after navigation
  const closeMenuAfterNavigation = () => {
    setMenuActive(false);
    setDropDownId(null);
  };

  return (
    <header className={`header-section `}>
      <div className="container">
        <div className="header-wrapper">
          <div className="main__logo">
            <Link to={"/"} className="logo">
              <img src={logo} alt="logo" height={70} width={70} loading="eager" />
            </Link>
          </div>
          <ul className={`main-menu ${menuActive ? "active" : ""}`}>
            {menuList.map(({ id, name, path, dropDown, section }) => {
              return (
                <li key={id} onClick={() => handleSubMenu(id)}>
                  <HashLink
                    smooth
                    to={`${path}${section ? section : ""}`}
                    onClick={closeMenuAfterNavigation} // Close menu on main menu item click
                  >
                    {name}
                  </HashLink>
                  {dropDown?.length && (
                    <ul
                      className={`sub-menu ${dropDownId === id ? "sub-menu_active" : ""}`}
                    >
                      {dropDown.map(({ id, name, path }) => {
                        return (
                          <li key={id}>
                            <HashLink
                              smooth
                              to={path}
                              onClick={closeMenuAfterNavigation} // Close menu on submenu click
                            >
                              {name}
                            </HashLink>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <div className="menu__components d-flex align-items-center">
            <Link
              to="/contact"
              className="d-flex fw-500 cmn--btn align-items-center gap-2"
              onClick={closeMenuAfterNavigation} // Close menu on 'Contact' click
            >
              <span className="get__text fw-bold">Contact</span>
              <span>
                <ArrowRight className="fz-20" />
              </span>
            </Link>
            <div
              onClick={handleHeaderToggle}
              className={`header-bar d-lg-none ${menuActive ? "active" : ""}`}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <div
              onClick={() => setIsSidebarActive(true)}
              className="remove__click"
            >
              <List className="icon" />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        isSidebarActive={isSidebarActive}
        setIsSidebarActive={setIsSidebarActive}
      />
    </header>
  );
};

export default Header;
