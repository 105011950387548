import React, { useState } from "react";
import { ArrowRight } from "react-bootstrap-icons";

const Form = ({ isColTwo }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission

    try {
      const response = await fetch("https://latest.catalunyaconnect.fr/wp-json/myplugin/v1/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          message: formData.message,
        }),
      });

      if (response.ok) {
        setSuccessMessage("Votre message a été envoyé avec succès !");
        setErrorMessage(""); // Clear any previous error message
        // Reset form fields
        setFormData({ name: "", email: "", message: "" });
      } else {
        throw new Error("Erreur lors de l'envoi du message");
      }
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("Il y a eu une erreur lors de l'envoi de votre message. Veuillez réessayer.");
    }
  };

  return (
    <div className="replay__box cmn__bg">
      <h3>Laissez-nous un message, nous vous recontacterons rapidement.</h3>
      <p>Les champs marqués * sont obligatoires.</p>
      <form onSubmit={handleSubmit} className="row g-4">
        <div className={` ${isColTwo ? "col-lg-6" : "col-lg-12"}`}>
          <input
            type="text"
            name="name"
            placeholder="* Nom"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className={` ${isColTwo ? "col-lg-6" : "col-lg-12"}`}>
          <input
            type="email"
            name="email"
            placeholder="* Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="col-lg-12">
          <textarea
            name="message"
            rows="5"
            placeholder="* Votre message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="col-lg-12">
          <button type="submit" className="d-flex fw-500 cmn--btn align-items-center gap-2">
            <span className="get__text">Envoyer</span>
            <span>
              <i className="fz-20">
                {" "}
                <ArrowRight />{" "}
              </i>
            </span>
          </button>
        </div>
      </form>
      {successMessage && <p className="success-message text-success">{successMessage}</p>}
      {errorMessage && <p className="error-message text-danger">{errorMessage}</p>}
    </div>
  );
};

export default Form;
