import { createBrowserRouter } from "react-router-dom";
import Root from "../Layouts/Root";
import Home from "../Pages/Home";
import AllServices from "../Pages/AllServices";
import ServiceDetails from "../Pages/ServiceDetails";
import Contact from "../Pages/Contact/Contact";
import BlogDetails from "../Pages/BlogDetails/BlogDetails";
import Tarifs from "../Pages/Tarifs/Tarifs";
import AllBlogs from "../Pages/AllBlogs/AllBlogs";
import TermsAndConditions from "../Pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../Pages/Privacy/PrivacyPolicy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/services",
        element: <AllServices />,
      },
      {
        path: "/services/:serviceId",
        element: <ServiceDetails />,
      },
      {
        path: "/blog",
        element: <AllBlogs/>,
      },
      {
        path: "/blog/:slug",
        element: <BlogDetails />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/tarifs",
        element: <Tarifs/>,
      },   {
        path: "*",
        element: <Root />,  // Or a 404 component if you prefer
      },   {
        path: "/terms",
        element: <TermsAndConditions />,  // Or a 404 component if you prefer
      }
      ,   {
        path: "/privacy",
        element: <PrivacyPolicy />,  // Or a 404 component if you prefer
      }
    
    ],
  },
]);
