import React from "react";
import { ArrowRight } from "react-bootstrap-icons";
import { Link, useParams } from "react-router-dom";
import Accordion from "../Components/Accordion/Accordion";
import { ScrollRestoration } from "react-router-dom";
import './serviceDetails.scss';
import { Helmet } from "react-helmet-async";
import seopic from "./../assets/img/about/seolike.svg"
import googleads from "./../assets/img/about/googleads.svg"
import creator from "./../assets/img/about/creation.svg";
const ServiceDetails = () => {
  const { serviceId } = useParams(); // Retrieve the serviceId from the URL
  const service = serviceData[serviceId]; // Retrieve the service data

  if (!service) {
    return <div className="text-black">Service non trouvé</div>;
  }
const structuredData = {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.heading,
    "description": service.meta,
    "provider": {
      "@type": "Organization",
      "name": "CatalunyaConnect",
      "url": "https://catalunyaconnect.fr",
      "logo": "https://catalunyaconnect.fr/logo.png",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "4 rue Lieutnant Farriol",
        "addressLocality": "Perpignan",
        "addressRegion": "Occitanie",
        "postalCode": "66000",
        "addressCountry": "FR"
      }
    },
    "areaServed": "France",
    "offers": {
      "@type": "Offer",
      "url": `https://catalunyaconnect.fr/services/${serviceId}`,
      
      "priceCurrency": "EUR",
      "availability": "https://schema.org/InStock"
    }
  };
  
  return (
    <>
         <Helmet>
        <title>{service.heading}</title>
        <meta name="description" content={service.meta} />
        <meta name="keywords" content="web development, web design, Perpignan, website creation, CatalunyaConnect" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={`https://catalunyaconnect.fr/services/${serviceId}`} /> {/* Dynamically set the canonical URL */}
        <meta name="msvalidate.01" content="EA6CA7422B2AB6DBA21D556A601377BA" />
        <meta property="og:url" content={service.link}/>
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>

      </Helmet>
      <section className="service__details overhid pb-120">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-8">
              <div className="ser__left__details">
                <div className="col-12 d-flex justify-content-center pt-5">
                  <img src={service.image} alt={service.heading} height={300} width={300} />
                </div>
                <div className="text__box mb__cus60" data-aos="fade-up" data-aos-duration="1400">
                  <h1 className="textt36 d-block service-head">Nos services de {service.heading}</h1>
                  <div>{service.description}</div>
                </div>
                <div className="ser__components">
                  <h2 className="whites mb-5">Questions fréquentes</h2>
                  <div className="accordion" id="accordionExample">
                    {service.accordionList.map(({ id, heading, para }) => (
                      <Accordion key={id} id={id} heading={heading} para={para} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className="service__right__wrap">
                <div className="service__rightbox mb-30">
                  <h3 className="service-head">Nos services</h3>
                  <Link to="/contact" className="link__box mb-15">
                    Création de Site Vitrine
                    <i className="bi bi-chevron-right"></i>
                  </Link>
                  <Link to="/contact" className="link__box mb-15">
                    Refonte De Sites Existants
                    <i className="bi bi-chevron-right"></i>
                  </Link>
                  <Link to="/contact" className="link__box mb-15">
                    Développement De Sites E-Commerce
                    <i className="bi bi-chevron-right"></i>
                  </Link>
                  <Link to="/contact" className="link__box">
                    Développement De Sites Sur Mesure
                    <i className="bi bi-chevron-right"></i>
                  </Link>
                </div>
                <div className="service__rightbox">
                  <div className="thumb">
                    <img src={service.image} height={200} alt="Service" />
                    <Link to="/contact" className="cmn--btn">
                      Contactez-nous
                      <span>
                        <i><ArrowRight /></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScrollRestoration />
    </>
  );
};

const serviceData = {
  "creation-de-site-web": {
    heading: "Création de site web",
    meta : "création de site web a perpignan , wordpress , site vitrine, refonte de site existant",
    description: (
      <div>
        <p className="h5">Chez CatalunyaConnect, nous savons qu’un site web bien conçu et fonctionnel est essentiel pour toute entreprise. Que vous soyez une petite entreprise, un artisan ou un professionnel, un site web de qualité est l’un des meilleurs moyens d’attirer de nouveaux clients.</p><br/>
        <h2>Nos services de création de sites web incluent :</h2><br/>
        <ul className="h2">
          <li>- <b>Design personnalisé:</b><br/><p className="mt-3">  Chaque entreprise est unique, tout comme son site. Chez <Link to="/contact" style={{color:'#c9f31d'}}>CatalunyaConnect</Link>, nous croyons que le design d’un site web doit être une extension de votre identité de marque. Nous collaborons étroitement avec vous pour concevoir un site qui reflète parfaitement votre marque et vos objectifs commerciaux. Notre équipe de designers expérimentés commence par une compréhension approfondie de votre vision et de votre secteur d’activité.

Nous créons des maquettes sur mesure qui intègrent vos couleurs, logos et éléments visuels, garantissant ainsi une expérience utilisateur fluide et engageante. Chaque détail compte, que ce soit la sélection des typographies, la disposition des éléments ou l’intégration de contenus multimédias. Notre objectif est de vous offrir un site qui attire l’attention et incite les visiteurs à explorer vos offres.</p></li><br/>
          <li>-<b>Design responsive :</b> <br/><p className="mt-3">À l'ère du mobile, il est essentiel que votre site soit aussi performant sur un smartphone que sur un ordinateur. Avec plus de 60% du trafic web provenant des appareils mobiles, chaque aspect de votre site doit être optimisé pour garantir une navigation fluide sur tous les appareils. Tous nos designs sont conçus en tenant compte de la réactivité, assurant que votre contenu s'affiche parfaitement, quelle que soit la taille de l'écran.

Nous utilisons des techniques de design adaptatif pour ajuster automatiquement la mise en page, les images et les fonctionnalités de votre site. Que vos visiteurs naviguent sur un smartphone, une tablette ou un ordinateur de bureau, ils bénéficieront d'une expérience utilisateur optimale. Cela contribue non seulement à la satisfaction des utilisateurs, mais améliore également votre classement dans les résultats des moteurs de recherche.</p></li><br/>
          <li>-<b>Optimisation <Link to="/services/seo" style={{color : '#c9f31d'}}>SEO</Link> :</b><p className="mt-3">Nous intégrons les meilleures pratiques de référencement naturel dès la conception de votre site pour améliorer sa visibilité sur les moteurs de recherche. Une bonne optimisation SEO est cruciale pour attirer un trafic organique qualifié. Dès le départ, notre équipe réalise une analyse approfondie des mots-clés pertinents pour votre secteur, puis nous appliquons ces mots-clés stratégiquement à travers votre contenu, vos titres et vos balises.

Nous veillons également à ce que la structure de votre site soit optimisée pour le SEO, en créant une hiérarchie claire, des URL conviviales et un maillage interne efficace. En intégrant des balises meta, des descriptions et des alt text pour vos images, nous maximisons vos chances d'être bien référencé. Grâce à notre expertise en SEO, votre site pourra se positionner en tête des résultats de recherche, augmentant ainsi votre visibilité et votre attractivité.</p></li><br/>
          <li>-<b>Solutions e-commerce :</b><p className="mt-3"> Pour les entreprises de vente en ligne, nous proposons des solutions e-commerce sécurisées et performantes, assurant une expérience d'achat fluide pour vos clients. Notre approche comprend la création de boutiques en ligne adaptées à vos besoins, avec des systèmes de paiement fiables et une gestion des produits intuitive.

Nous intégrons des fonctionnalités telles que des catalogues de produits attractifs, des options de filtrage et de recherche, ainsi que des systèmes de panier et de commande simplifiés. En plus de la mise en place technique, nous vous accompagnons dans l'optimisation de l'expérience utilisateur pour maximiser le taux de conversion. Que vous soyez un petit artisan ou une grande entreprise, nos solutions e-commerce sont conçues pour favoriser la croissance de vos ventes en ligne.</p> </li><br/>
          <li>-<b>Maintenance et mises à jour :</b><p className="mt-3"> Votre site web nécessite un entretien régulier pour rester performant et sécurisé. Nous offrons des services de maintenance pour assurer la continuité de votre activité en ligne. Cela inclut des mises à jour régulières des plugins, des thèmes et des systèmes de gestion de contenu, garantissant ainsi que votre site fonctionne sans accroc.

Nous surveillons également la sécurité de votre site, en effectuant des sauvegardes régulières et en appliquant des mesures de sécurité pour protéger vos données et celles de vos clients. En plus de cela, nous proposons des rapports d’analyse de performance, vous permettant de suivre les visites, les conversions et d’autres indicateurs clés. Grâce à notre service de maintenance, vous pouvez vous concentrer sur votre activité principale en sachant que votre présence en ligne est entre de bonnes mains.</p></li><br/>
        </ul>
        <h3>Pourquoi choisir CatalunyaConnect ?</h3>
        <p className="mt-3">Nous travaillons avec des artisans, des TPE et des PME pour offrir des solutions sur-mesure, qu'il s'agisse de sites vitrines, de refontes ou de sites e-commerce. Notre approche est centrée sur la satisfaction du client et la mise en place d'une stratégie web efficace, tout en respectant vos contraintes budgétaires.</p>
      </div>
    ),
    image: creator,
    accordionList: [
      {
        id: "faq1",
        heading: "Quelle est la durée pour créer un site web ?",
        para: "Le temps de création varie en fonction de la complexité, entre 2 à 6 semaines.",
      },
      {
        id: "faq2",
        heading: "Quelles sont les options de maintenance ?",
        para: "Nous offrons des services de maintenance pour garder votre site sécurisé et à jour.",
      },
      {
        id: "faq3",
        heading: "Puis-je modifier le contenu de mon site moi-même après sa création ?",
        para: "Absolument ! Nous utilisons des CMS (systèmes de gestion de contenu) qui vous permettent de modifier facilement le texte, les images, et même de publier des articles de blog sans compétences techniques.",
      },
      {
        id: "faq4",
        heading: "Proposez-vous un hébergement pour les sites web ?",
        para: "Oui, nous offrons des services d'hébergement adaptés à vos besoins. Vous pouvez opter pour l'hébergement géré directement par nous ou choisir votre propre fournisseur d'hébergement.",
      }
    ]
  },
  "seo": {
    meta : "services seo a perpignan on page seo off page seo et netlinking",
    heading: "SEO (Optimisation pour les moteurs de recherche)",
    description: (
      <div>
        <p className="h5">Le SEO est une stratégie clé pour améliorer la visibilité de votre site sur les moteurs de recherche. Dans un environnement numérique en constante évolution, avoir une présence en ligne bien positionnée est essentiel pour attirer des clients potentiels. Chez CatalunyaConnect, nous mettons en œuvre des techniques éprouvées pour vous aider à grimper dans les classements des moteurs de recherche et à vous démarquer de la concurrence.</p><br/>
        <h2>Nos services SEO incluent :</h2><br/>
        <ul className="h3">
          <li>- <b>SEO on-page :</b><p className="mt-3"> Nous optimisons les éléments internes de votre site, tels que les balises méta, les titres, et la structure du contenu, pour le rendre plus attractif pour les moteurs de recherche. Cela inclut l’optimisation des descriptions de pages, l’utilisation appropriée des balises H1, H2, H3, et l’insertion de mots-clés stratégiques tout au long de votre contenu.

En parallèle, nous nous assurons que le contenu est de haute qualité et pertinent pour vos visiteurs, ce qui améliore également l'expérience utilisateur. Notre objectif est de faire en sorte que votre site soit à la fois convivial et facilement indexable par les moteurs de recherche, ce qui contribue à améliorer votre classement organique.</p></li><br/>
          <li>- <b>SEO technique :</b><p className="mt-3">Nous nous concentrons sur l’amélioration de la vitesse de chargement, la compatibilité mobile et d’autres aspects techniques pour garantir un site performant aux yeux de Google. Les moteurs de recherche privilégient les sites qui offrent une bonne expérience utilisateur, ce qui inclut des temps de chargement rapides et une navigation fluide sur tous les appareils.

Nous réalisons des audits techniques approfondis pour identifier et corriger les problèmes qui pourraient nuire à votre référencement, comme des erreurs 404, des redirections incorrectes ou des balises manquantes. Notre approche garantit que votre site respecte les meilleures pratiques techniques, vous permettant ainsi de bénéficier d'un meilleur positionnement dans les résultats de recherche.</p> </li><br/>
          <li>- <b>Recherche de mots-clés :</b><p className="mt-3">Nous analysons les mots-clés les plus pertinents pour votre secteur afin d'attirer un trafic qualifié sur votre site. En comprenant les termes que vos clients potentiels recherchent, nous pouvons développer une stratégie de contenu ciblée qui répond à leurs besoins et intérêts.

Notre processus inclut une analyse approfondie de la concurrence et des tendances de recherche pour identifier les opportunités à exploiter. Nous intégrons ces mots-clés de manière stratégique dans votre contenu, ce qui améliore votre visibilité sur les moteurs de recherche et attire un public plus engagé.</p></li><br/>
          <li>- <b>Netlinking :</b><p className="mt-3">Le renforcement de la notoriété de votre site grâce à des liens entrants de qualité provenant d'autres sites influents dans votre secteur. Les backlinks de haute qualité sont un facteur déterminant pour le classement de votre site, car ils signalent aux moteurs de recherche que votre contenu est crédible et pertinent.

Nous développons des stratégies de netlinking personnalisées qui incluent la création de contenu de qualité, la recherche de partenariats avec d'autres sites, et l'établissement de relations avec des influenceurs dans votre domaine. En augmentant le nombre de liens de qualité pointant vers votre site, nous contribuons à améliorer votre autorité en ligne et votre classement dans les résultats de recherche.</p></li><br/>
        </ul><br/>
        <h3>Quels sont les avantages ?</h3>
        <p className="mt-3">Un bon référencement naturel permet d'attirer un trafic qualifié sans avoir recours à la publicité payante. Avec une stratégie SEO efficace, vous pouvez vous attendre à une augmentation du nombre de visiteurs sur votre site, ce qui peut se traduire par une hausse des conversions et des ventes.

Notre équipe met en place une stratégie SEO qui maximise votre retour sur investissement en augmentant la visibilité de votre entreprise à long terme. En investissant dans le SEO, vous créez une base solide pour votre présence en ligne, ce qui vous permet de vous concentrer sur votre cœur de métier tout en bénéficiant d’un flux constant de clients potentiels.</p>
      </div>
    ),
    image: seopic,
    accordionList: [
      {
        id: "faq5",
        heading: "Combien de temps pour voir les résultats du SEO ?",
        para: "Les résultats SEO prennent généralement entre 3 et 6 mois.",
      },
      {
        id: "faq6",
        heading: "Qu'est-ce que le netlinking ?",
        para: "C'est une stratégie pour obtenir des liens entrants vers votre site pour améliorer son autorité.",
      },
      {
        id: "faq7",
        heading: "Quelle est la différence entre le SEO on-page et off-page ?",
        para: "Le SEO on-page concerne les optimisations internes à votre site, comme le contenu, les balises et la structure. Le SEO off-page se concentre sur les éléments externes, comme le netlinking (liens entrants) et la réputation en ligne.",
      },
      {
        id: "faq8",
        heading: "Mon site est-il pénalisé s'il n'est pas optimisé pour le mobile ?",
        para: "Oui, Google favorise les sites web optimisés pour les mobiles. Un site qui n’est pas responsive pourrait voir son classement diminuer dans les résultats de recherche.",
      }
    
    ]
  },
  "marketing-digital": {
    heading: "Marketing Digital",
    meta : "marketing digital a perpignan , google ads , recherche de mots clés , marketing reseaux sociaux",
    description: (
      <div>
        <p className="h5">Nous mettons notre expertise en marketing digital à votre service pour accroître votre visibilité. À l’ère numérique, il est essentiel de se démarquer et d'attirer l'attention de votre public cible. Chez CatalunyaConnect, nous développons des stratégies sur mesure qui répondent aux besoins spécifiques de votre entreprise, en garantissant un retour sur investissement optimal.</p><br/>
        <h2>Nos services de marketing digital incluent :</h2><br/>
        <ul className="h3">
          <li>- <b >Campagnes Google Ads:</b> <p className="mt-3">Nous mettons en place des publicités ciblées sur Google pour générer des prospects qualifiés et augmenter vos conversions. Grâce à notre connaissance approfondie des outils Google Ads, nous concevons des campagnes personnalisées qui répondent à vos objectifs commerciaux.

Nous réalisons une recherche approfondie des mots-clés pour cibler les termes de recherche pertinents pour votre secteur, puis nous optimisons vos annonces pour maximiser leur impact. Nous suivons les performances de chaque campagne en temps réel et ajustons nos stratégies pour garantir des résultats optimaux. Avec des rapports détaillés, vous pourrez mesurer l’efficacité de votre investissement publicitaire et voir comment chaque euro dépensé contribue à votre croissance.</p></li><br/>
          <li>- <b>Marketing sur les réseaux sociaux :</b><p className="mt-3"> Nous élaborons des stratégies sur Facebook, Instagram, LinkedIn et d'autres plateformes pour engager votre audience et renforcer votre présence en ligne. Notre équipe crée des contenus visuels et textuels captivants qui attirent l'attention et incitent à l'interaction.

Nous analysons également les comportements de votre audience pour affiner notre approche et optimiser vos publications. En intégrant des publicités payantes et en gérant vos communautés, nous maximisons votre portée et renforçons l’engagement de votre public. Grâce à des rapports réguliers, vous aurez un aperçu clair de l’évolution de votre image de marque et des interactions générées sur les réseaux sociaux.</p></li><br/>
          <li>- <b>Email marketing :</b><p className="mt-3"> Nos campagnes d'emailing sont conçues pour fidéliser vos clients et renforcer l'engagement autour de votre marque. Nous développons des newsletters attrayantes et informatives qui informent vos abonnés sur vos nouveautés, offres spéciales et contenus pertinents.

En segmentant votre base de données, nous assurons que chaque message atteint le bon public avec le bon contenu. De la conception à l’envoi, nous gérons chaque étape du processus pour garantir un taux d’ouverture et de clics optimal. Nous analysons également les résultats pour ajuster les futures campagnes, vous permettant ainsi de construire une relation durable avec vos clients et prospects.</p> </li><br/>
          <li>- <b>Marketing de contenu :</b><p className="mt-3">Nous créons du contenu sur-mesure, pertinent pour votre audience, afin d'améliorer votre visibilité et de renforcer votre autorité en ligne. Notre approche inclut la rédaction d'articles de blog, de livres blancs, d'infographies et de vidéos qui informent, éduquent et divertissent vos visiteurs.

Nous nous concentrons sur les besoins de votre audience cible et sur les sujets qui les intéressent, tout en intégrant des mots-clés pour optimiser votre référencement. Ce contenu de qualité ne sert pas seulement à attirer des visiteurs, mais également à les convertir en clients fidèles, en positionnant votre marque comme un leader d’opinion dans votre secteur.</p></li><br/>
        </ul><br/>
        <h3>Pourquoi investir dans le marketing digital ?
        </h3>
        <p className="mt-3">Une stratégie digitale bien pensée vous permet de toucher un public large et ciblé, de renforcer votre image de marque, et de convertir des prospects en clients grâce à des méthodes de marketing mesurables et adaptables. En utilisant des outils d’analyse avancés, nous pouvons suivre l’efficacité de chaque campagne et ajuster notre approche en temps réel pour maximiser vos résultats.

Investir dans le marketing digital vous aide non seulement à améliorer votre visibilité, mais également à créer des relations durables avec vos clients, à anticiper leurs besoins et à vous adapter rapidement aux évolutions du marché. Grâce à notre expertise, nous vous aidons à naviguer dans le paysage numérique complexe et à tirer le meilleur parti de votre présence en ligne.</p>
      </div>
    ),
    image: googleads,
    accordionList: [
      {
        id: "faq9",
        heading: "Quels réseaux sociaux sont couverts ?",
        para: "Nous travaillons avec Facebook, Instagram, LinkedIn et plus encore.",
      },
      {
        id: "faq10",
        heading: "Pourquoi investir dans Google Ads ?",
        para: "Les campagnes Google Ads vous permettent de toucher un public ciblé immédiatement.",
      },
      {
        id: "faq11",
        heading: "Comment le marketing digital peut-il aider mon entreprise à se développer ?",
        para: "Le marketing digital permet d'atteindre un large public ciblé, d'améliorer la visibilité de votre marque, et de générer plus de prospects et de ventes grâce à des stratégies personnalisées et mesurables.",
      },
      {
        id: "faq12",
        heading: "Combien de temps faut-il pour voir les résultats du marketing digital ?",
        para: "Les premiers résultats peuvent être visibles en quelques semaines, mais le marketing digital nécessite un suivi et des ajustements continus pour atteindre des objectifs à long terme.",
      }
    ]
  }
};


export default ServiceDetails;
