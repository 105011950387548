import React, {  Suspense, lazy } from "react";
import Title from "../Shared/Title/Title";
import WorkProcessCard from "./WorkProcessCard";


const processList = [
  {
    id: 1,
    title: "Conception",
    info: " La première étape consiste à comprendre vos besoins spécifiques et à définir les objectifs de votre site web. Nous travaillons en étroite collaboration avec vous pour recueillir toutes les informations nécessaires afin de créer un plan clair.",
    list: [
      "Discussion des besoins et des objectifs du client.",
      "Création d'une maquette fonctionnelle (wireframe) pour visualiser la structure du site.",
      "Définition des fonctionnalités essentielles à intégrer.",
    ],
  },
  {
    id: 2,
    title: "Design",
    info: "Une fois la conception validée, nous passons à la phase de design. Cela implique de créer une interface attrayante et intuitive qui reflète votre identité visuelle.",
    list: [
      "Élaboration de maquettes visuelles (mockups) basées sur la maquette fonctionnelle.",
      "Choix des couleurs, typographies et éléments graphiques en accord avec votre image de marque.",
      "Intégration d’une expérience utilisateur (UX) optimale.",
    ],
  },
  {
    id: 3,
    title: "Accompagnement",
    info: " Après le lancement du site, nous continuons à vous accompagner pour garantir son succès. Cela inclut le suivi des performances et les ajustements nécessaires.",
    list: [
      "Formation à l'utilisation de votre nouveau site web.",
      "Suivi et optimisation des performances du site.",
      "Support technique et mises à jour régulières.",
    ],
  },
];
const WorkeProcess = () => {
  return (
    <section className="process__section pt-120 pb-120">
      <div className="container">
       
        <div className="row g-4">
          {processList.map(({ id, info, list, title }) => (
            <WorkProcessCard className="h-100" key={id} info={info} list={list} title={title} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default WorkeProcess;
