import React from "react";
import { ArrowUpShort, ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { AOS } from "aos";
import { useNavigate } from "react-router-dom";

const socalList = [
  {
    id: 1,
    platfrom: "Facebook",
    icon: <ArrowRight />,
    sociallink : "https://www.facebook.com/profile.php?id=61557100655573",
  },
  {
    id: 2,
    platfrom: "Instagram",
    icon: <ArrowRight />,
    sociallink : "https://www.instagram.com/baitechebillal/",
  },
  {
    id: 3,
    platfrom: "Twitter/x",
    icon: <ArrowRight />,
    sociallink : "https://www.x.com/Catalunyacnnect",
  },
  
];
const Footer = () => {
  const navigate = useNavigate();
  
  const handleLinkClick = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to the top
    setTimeout(() => {
      navigate(path); // Redirect after scrolling
    }, 300); // Delay for scroll animation
  };
  return (
    <footer className="footer__section p-5">
      <div className="container">
        <div className="footer__top pt-60 pb-120">
          <div className=" p-5 d-flex justify-content-center" data-aos="fade-up" data-aos-duration="1000">
          <p className="h3 ">Contactez-nous</p>
          </div>
          <div className="row g-4">
            <div className="col-lg-6">
              <div className="get__content">
                <p className="h3 leading-6	">
                CatalunyaConnect, votre agence web spécialisée dans la <strong>création de sites internet</strong> sur mesure, le <strong>référencement SEO</strong> et le <strong>marketing numérique</strong>, basée à <strong>Perpignan</strong>.
                </p>
                <a href="mailto:contact@catalunyaconnect.fr">contact@catalunyaconnect.fr</a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get__rightcontetn">
                <div className="row g-4">
                  {socalList.map(({ id, icon, platfrom , sociallink }) => {
                    return (
                      <div key={id} className="col-lg-6 col-md-6 col-sm-6">
                        <Link to={sociallink} target="_blank" className="social__footer">
                          {platfrom}
                          <i>{icon}</i>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__bottom">
        <div className="container">
          <div className="copyright col-12 d-flex">
           
            <ul className="terms">
              <li>
              <button className="p-2 rounded fw-bold footbtn" onClick={() => handleLinkClick('/terms')}>
            Terms et conditions
          </button>              </li>
              <li>
              <button className="p-2 rounded fw-bold footbtn" onClick={() => handleLinkClick('/privacy')}>
            politique de cofidentialité
          </button> 
              </li>
            </ul>
            <a href="#about" className="toparrow">
              <i>
                <ArrowUpShort />
              </i>
            </a>
          </div>
        </div>
        
      </div>
    </footer>
  );
};

export default Footer;
